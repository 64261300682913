import './assets/vendor/locomotive/locomotive-scroll.css'
import './assets/scss/style.scss'
import './assets/scripts/script.js'

import LocomotiveScroll from 'locomotive-scroll';

const scroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true,
    multiplier: 1.5
});
